<template>
    <!-- <div :class="[flag? '': 'content-box']"> -->
        <!-- <div :class="[flag? '': 'container']"> -->
            <div class="content">
            <h4 class="global_head_title">添加/编辑工人</h4>
            <div class="content_row flex">
                <label class="info" for="">姓名 <span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.name" size="large" placeholder="请输入姓名"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">性别 <span class="redS">*</span></label>
                  <div class="content_right">
                     <a-select v-model="data.sex" class=" ant-select-lg">
                        <a-select-option :value="1">男</a-select-option>
                        <a-select-option :value="2">女</a-select-option>
                    </a-select>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">年龄</label>
                  <div class="content_right">
                    <a-input v-model="data.age" size="large" type="number" placeholder="请输入年龄"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">身份证号<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.code" size="large" placeholder="请输入身份证号"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">电话 <span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.tel" size="large" placeholder="请输入电话"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">是否有工伤保险</label>
                  <div class="content_right">
                    <a-select v-model="data.insurance" class="ant-select-lg">
                        <a-select-option :value="1">有</a-select-option>
                        <a-select-option :value="2">没有</a-select-option>
                    </a-select>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">保险到期时间</label>
                  <div class="content_right">
                    <a-date-picker v-model="deadline" :inputReadOnly="true" @focus="focus"  size="large" @change="onPickTime" />
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">技能（可多选）</label>
                  <div class="content_right">
                      <a-select  mode="multiple" :inputReadOnly="true" v-model="selSkillid"  class="ant-select-lg">
                        <a-select-option  :value="item.id" v-for="(item,index) in skillid" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">状态</label>
                  <div class="content_right">
                   <a-select v-model="data.status" class="ant-select-lg">
                        <a-select-option :value="1">空闲中</a-select-option>
                        <a-select-option :value="2">工作中</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">职务</label>
                  <div class="content_right">
                    <a-select v-model="data.post" class="ant-select-lg">
                        <a-select-option :value="'正式工'">正式工</a-select-option>
                        <a-select-option :value="'临时工'">临时工</a-select-option>
                        <a-select-option :value="'负责人'">负责人</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">银行卡号</label>
                  <div class="content_right">
                    <a-input v-model="data.card" size="large" placeholder="请输入银行卡号"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">跟进人</label>
                  <div class="content_right">
                       <a-input size="large"  @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择跟进人" />
                     <!-- <a-select v-model="data.uid"  class="ant-select-lg">
                        <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">紧急联系人</label>
                  <div class="content_right">
                    <a-input v-model="data.contacts" size="large" placeholder="请输入紧急联系人"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">紧急联系人电话</label>
                  <div class="content_right">
                    <a-input v-model="data.contactstel" size="large" placeholder="请输入紧急联系人电话"/>
                </div>
            </div>
            <div class="content_row flex" >
                <label class="info" for="">备注</label>
                <div class="content_right">
                    <a-textarea v-model="data.remarks" placeholder="请输入备注" :auto-size="{ minRows: 5, maxRows: 8 }"/>
                </div>
            </div>

            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
            <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
            </div>
        <!-- </div> -->
        <!-- </div> -->
</template>
<script>
// import $ from "jquery";
import Staff from "../../../components/staff.vue";
import {isMobile,ajaxUrl,requestXml, getDateTime} from '../../../assets/js/request';
export default {
    components: {
        Staff,
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "name": "",
                "sex": "",
                "age": "",
                "code": "",
                "tel": "",
                "insurance": "",
                "skillid": "",
                'deadline':'',
                "status": "",
                "post": "",
                "card": "",
                "contacts": "",
                "contactstel": "",
                "uid": "",
                "remarks": "",
            },
            deadline:'', //保险到期时间
            selSkillid:undefined,  //技能选项
            staffFlag: false,
            onStaff:{
                'name':'',
                'userid':'',
            },
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        // this.getStaff();
        this.getSkillid();
         if(this.id){
            // this.getDetail();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        submit(){
            let url='/jieb/Temporary/tempbaseadd';
            if(this.id){
                url='/jieb/Temporary/tempbasedit';
                this.data.id=this.id;
            }
            // delete this.data.jsonname;
            if(this.selSkillid&&this.selSkillid.length>1){this.data.skillid=this.selSkillid.join(',')}else{
                this.data.skillid=this.selSkillid
            }
            this.data.uid=this.onStaff.userid;
            delete this.data.responsibility_name;
             requestXml(url,"POST",(res) => {
                // console.log(res);
                if(res!='error'){
                    if(!this.id){
                        this.$message.success('成功');
                    }
                    let path="/scrm_pc/temp/tempList";
                    if(isMobile()){
                        path = '/scrm_wap/temp/tempList';
                    }
                    this.$router.push({
                        path: path,
                    });
                }
                // if(res){
                //     this.$message.info(res.msg);
                // }
            },this.data)
        },
          // 负责人-输入框聚焦是清除其焦点
        focus(e) {
        e.srcElement.blur();
        },
        // dataPick(e){
        // e.srcElement.blur();
        // },
        // 分配完
        updateStaffFlag(data) {
        // console.log(data,"dasds")
        if (data != 1) {
            this.onStaff = data;
        }
            this.staffFlag = false;
        },
        onPickTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.deadline= Math.round(time/1000); 
        },
        // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillid=res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));
                if(this.id){
                    this.getDetail();
                }
                // console.log(this.skillid);
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Temporary/tempbasedit","GET",(res) => {
            //    console.log(res);
                if(res.skillid){
                    let selSkillid=res.skillid.split(',');
                    for (let index = 0; index < selSkillid.length; index++) {
                        selSkillid[index]=Number(selSkillid[index]);
                    }
                    this.selSkillid=selSkillid;
                }
                if(res.deadline!=0){
                    this.deadline=getDateTime(res.deadline);
                }else{
                    this.deadline='';
                }
                console.log(this.deadline);
                this.data = Object.assign({}, res);
                this.onStaff.name = res.responsibility_name;
                this.onStaff.userid = res.uid;
                this.data.insurance==0?this.data.insurance='':'';
                this.data.status==0?this.data.status='':'';
                this.data.sex==0?this.data.sex='':'';

            },{id: this.id})
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 130px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
